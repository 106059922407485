import React from 'react';
import {get} from 'lodash';

import FractionInput from './FractionInput';


export default (props) => {

    const renderInput = (fieldKey) => {
        const fieldData = get(props.itemData, ['inputs', fieldKey]);
        return (
            <FractionInput
                component_data={props.component_data}
                is_preview={props.is_preview}
                answer={props.answer}
                existing_answer={props.existing_answer}
                onChange={props.onChange(fieldKey)}
                fieldData={fieldData}
                fieldKey={fieldKey}
                fractionKey={props.fractionKey}
            />
        );
    }

    const fractionStyle = get(props.itemData, 'style');

    return (
        <div className="cc-fraction-fractionpart" style={fractionStyle}>
            <div className="flex-row flex-v-center">
                {renderInput("0")}
            </div>
        </div>
    )
}